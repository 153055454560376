@import "../../assets/styles/variables.scss";

.infoCard {
  position: relative;
  width: 172px;
  height: 172px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0.5rem #c20000 inset, 0px 0px 0px 1.2rem #000000 inset;

  @media only screen and (max-width: 1231px) {
    margin-bottom: 70px;
  }

  @media only screen and (max-width: 813px) {
    margin-bottom: 90px;
  }

  @media only screen and (min-width: 1600px) {
    width: 220px;
    height: 220px;
  }
}

.infoCard::before {
  content: "";
  position: absolute;
  bottom: -6.8rem;
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 8rem;
  width: 5rem;
  background-color: #000000;
  border-radius: 5px 5px 55px 55px;
}

.infoCard::after {
  content: "";
  position: absolute;
  top: 10.3rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.7rem;
  width: 0.5px;
  background-color: white;

  @media only screen and (max-width: 1200px) {
    top: 12rem;
  }
}

.infoIcon {
  max-width: 4rem;
  width: 100%;
  height: 4rem;
  border-radius: 50%;
  position: absolute;
  top: 12.8rem;
  left: 3.4rem;
  background-color: aliceblue;
  box-shadow: 0px 1px 1px 1px rgb(155, 45, 45) inset,
    0px 1px 5px 1px rgba(7, 7, 7, 0.529) inset;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1200px) {
    top: 14.5rem;
    left: 4.2rem;
  }

  img {
    max-width: 2rem;
    width: 100%;
    object-fit: contain;
  }
}

.infoCardbox {
  position: relative;
  width: 172px;
  height: 172px;
  border-radius: 50%;
  color: white;
  background-color: rgb(20, 20, 20);
  box-shadow: 0px 0px 0px 0.5rem #c20000 inset, 0px 0px 0px 1.2rem #000000 inset;
  border: 1px solid #ffffff;

  @media only screen and (max-width: 1231px) {
    margin-bottom: 70px;
  }

  @media only screen and (max-width: 813px) {
    margin-bottom: 90px;
  }

  @media only screen and (min-width: 1600px) {
    width: 220px;
    height: 220px;
  }
}
.infoCardbox::before {
  content: "";
  position: absolute;
  bottom: -6.8rem;
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 8rem;
  width: 5rem;
  background-color: rgb(7, 7, 7);
  border-radius: 5px 5px 55px 55px;
}
.infoCardbox::after {
  content: "";
  position: absolute;
  top: 10.3rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.7rem;
  width: 0.5px;
  background-color: white;
  z-index: 1;

  @media only screen and (max-width: 1200px) {
    top: 12rem;
  }
}
.infocardIcon {
  max-width: 4rem;
  width: 100%;
  height: 4rem;
  border-radius: 50%;
  position: absolute;
  top: 12.8rem;
  left: 3.4rem;
  background-color: aliceblue;
  box-shadow: 0px 1px 1px 1px rgb(155, 45, 45) inset,
    0px 1px 5px 1px rgb(5, 5, 5) inset;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1200px) {
    top: 14.5rem;
    left: 4.1rem;
  }

  img {
    max-width: 2rem;
    width: 100%;
    object-fit: contain;
  }
}

.dashboard_root {
  @media only screen and (min-width: 1600px) {
    align-items: flex-start !important;
    padding-top: 100px;
  }
}
