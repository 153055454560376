@import "../../assets/styles/variables.scss";

.freelanceHeader {
  padding: 0.7rem;
}
.freelancerCardWrapper {
  .freeLancerCard {
    max-width: 20rem;
    width: 100%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
    border-radius: 1rem;
    .avatar {
      width: 6.5rem;
      height: 6.5rem;
      border-radius: 1rem;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
.addImg {
  margin-top: 1rem;
  max-width: 10rem;
  width: 100%;
  height: 13rem;
  //   border: 1px solid $primary-clr;
  border-radius: 0.5rem;
  text-align: center;
  .upload {
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 50%;
    margin-top: 1.3rem;
    object-fit: cover;
  }
  .uploadTitle {
    border-top: 1px solid $primary-clr;
  }
}
.passwordsec {
  padding: 0;
  border-bottom: 1px solid rgb(225, 223, 223);
}
.serachInput {
  min-width: 20rem !important;
  // width: 100%;
}
.uploadImg {
  position: absolute !important;
  opacity: 0;
}
