.confirmDialog {
  z-index: 99999 !important;
  border-radius: 2rem;
  .dialogContent {
    padding: 0.5rem 0;
    margin: 0;
  }

  .footerSec {
    padding: 15px 25px 30px;
    justify-content: start;
    > button {
      // width: 100%;
      // border-radius: 2rem;
    }
  }
}
