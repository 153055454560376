.vjs-play-progress .vjs-time-tooltip{
  display: none !important;
  /* visibility: hidden !important; */
}

.vjs-seekbar-preview {
  position: absolute;
  top: -85px;
  transform: translateX(-50%);
  /* background: rgba(0, 0, 0, 0.8); Dark background for visibility */
  /* border: 1px solid #fff; Optional: add border for visibility */
  border-radius: 4px; /* Rounded corners */
  /* padding: 2px; */
  display: none; /* Hide by default */
  pointer-events: none; /* Prevent interaction with the preview */
  z-index: 1; /* Ensure it appears above other elements */
}

.vjs-preview-video {
  width: 100px; /* Adjust the width of the preview video */
  height: 60px; /* Adjust the height of the preview video */
  object-fit: cover; /* Cover the container */
}


.vjs-comment-marker {
  position: absolute;
  width: .5rem;
  height: .5rem;
  background-color: rgb(100, 255, 52); 
  border-radius: 50%;
  cursor: pointer;
  transform: translate(65%, -50%);
  pointer-events: auto;
  z-index: 2;
}

.vjs-comment-marker:hover::after {
  text-align: start;
  width: 130px;
  height: 65px;
  content: attr(title);
  position: absolute;
  background: rgb(255, 255, 255);
  color: #000000;
  padding: 3px 3px;
  border-radius: 2px;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  white-space: wrap;
  font-size: 8px;

  overflow: hidden;

  line-height: 1.1em; /* Adjust line height as needed */
  display: -webkit-box; /* Necessary for the multi-line truncation */
  line-clamp: 7;
  -webkit-line-clamp: 7; /* Number of lines to show before truncation */
  -webkit-box-orient: vertical; /* Specifies vertical orientation of the box */
  text-overflow: ellipsis; /* Add ellipsis at the end of the text */

}


/* .TEST{
  height: 100vh;
  position: absolute;
}

.CHILD-TEST{
  position: absolute;
  top: 0;
  left: 0;
} */

.vjs-custom-button {
  position: relative;
}

.vjs-custom-button::before {
  cursor: pointer;
  content: '';
  display: block;
  width: 100%;
  height: 16px;
  background: url('../../assets/images/add-comment.svg') no-repeat center;
  background-size: 15px;
}

.vjs-custom-button::after {
  content: 'Add Comment';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 10px;
  z-index: 1000;
}

.vjs-custom-button:hover::after {
  opacity: 1;
}

.vjs-button.vjs-custom-button{
  display: none;
}

.vjs-time-tooltip{
  top: -5.4em !important;
}