@import "./variables.scss";

// font utility
.txt-bold {
  font-family: "montserrat-bold";
}
.txt-light {
  font-family: "montserrat-light";
}
.txt-medium {
  font-family: "montserrat-medium";
}
.txt-xs {
  font-size: 0.6rem;
}
.txt-smd {
  font-size: 0.7rem;
}
.txt-md {
  font-size: 0.8rem;
}
.txt-xmd {
  font-size: 1rem;
}
.txt-lg {
  font-size: 1.2rem;
}
.txt-xlg {
  font-size: 2rem;
}
.txt-xl {
  font-size: 3rem;
}

// font color utility

.txt-primary {
  color: $primary-clr;
}
.txt-primaryclr {
  color: #c20000;
}
.txt-blue {
  color: $font-clr-1;
}
.txt-grey {
  color: $font-clr-2;
}
.txt-white {
  color: $white;
}
.txt-grey-drk {
  color: $font-clr-3;
}
.txt-success {
  color: $success-green;
}
.txt-error {
  color: $error-red;
}
.text-center {
  text-align: center;
}

// Generic

.m-w-100 {
  min-width: 100%;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-md-auto {
  width: 100%;
  @media screen and (min-width: 768px) {
    width: auto;
  }
}

.cursor {
  cursor: pointer;
}

.required-label::after {
  content: "*";
  color: var(--error-main);
  margin-left: 0.2em;
}
