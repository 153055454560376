@import "./bootstrap-utils.scss";
@import "./utility.scss";
@import "./variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "montserrat-bold";
  src: url("../fonts/Montserrat-Bold.otf");
}

@font-face {
  font-family: "montserrat-light";
  src: url("../fonts/Montserrat-Light.otf");
}

@font-face {
  font-family: "montserrat-medium";
  src: url("../fonts/Montserrat-Medium.otf");
}

@font-face {
  font-family: "montserrat-regular";
  src: url("../fonts/Montserrat-Regular.otf");
}

@font-face {
  font-family: "montserrat-semi-bold";
  src: url("../fonts/Montserrat-SemiBold.otf");
}

// Generic

a,
a:hover {
  // color: $primary-clr;
  text-decoration: none;
}

.loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #ffffff, $alpha: 0.8);
  width: 100%;
  height: 100vh;
  z-index: 1400;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $font-clr-1;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 500000s ease-in-out 0s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.4);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(136, 136, 136, 0.8);
}

// Video preview customization

.video-js .vjs-play-progress {
  background-color: $primary-clr !important;
}

.video-js .vjs-progress-holder {
  height: 0.6em !important;
}

.video-js .vjs-play-progress:before {
  font-size: 1.5em !important;
}

.video-js.vjs-fluid {
  border-radius: 0.3rem;
  overflow: hidden;
}

.vjs-poster {
  background-size: cover !important;
  background-position: center !important;
}

.video-js .vjs-progress-control:hover .vjs-progress-holder {
  font-size: inherit !important;
}

.vjs-fluid {
  padding-top: 56.25% !important;
}
.fhd-indicator {
  width: 1rem;
}
.close {
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-color: white;
  border-radius: 50%;
  z-index: 1;
  top: -2.4rem;
  right: -2.4rem;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.position-relative {
  position: relative;
}

.otp {
  width: 2.5rem !important;
  display: inline-block;
  height: 2.75rem;
  border: none;
  font-size: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-color: black;
  border-bottom-width: 1.5px;
  outline: none;
}

.seperator {
  padding: 0.5rem;
}

.otp-focus {
  // box-shadow: 0px 0px 7px 0px var(--primary-main);
}

.calendarIcon {
  width: 1.5rem;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}