@import "assets/styles/variables.scss";

.freeLancerCard {
  max-width: 17rem;
  min-width: 17rem;
  width: 100%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  background-color: black;
  border-radius: 0.2rem;
  cursor: pointer;
  .avatar {
    min-width: 5rem;
    max-width: 5rem;
    min-height: 6.5rem;
    border-radius: 0.2rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.switch {
  position: relative;
  bottom: 0.7rem;
  left: 0.6rem;
}
.info {
  max-width: 9.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.infoName {
  max-width: 6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: white;
}
.icon {
  width: 0.8rem;
  height: 0.8rem;
}

.iconDelete {
  width: 1.3rem;
  height: 1.3rem;
}
