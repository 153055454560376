.confirmDialog {
  z-index: 99999 !important;
  // border-radius: 2rem;
  .dialogContent {
    padding: 2rem 0;
    margin: 0;
    color: var(--text-primary);
    font-weight: 300;
  }

  .footerSec {
    padding: 20px 25px 30px;
    justify-content: center;
    > button {
      margin-right: 20px;
      width: 6rem;
    }
  }
}
.errorBtn {
  background-color: red;
}
