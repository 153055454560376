.commentBody {
  position: absolute;
  top: calc(4vh);
  height: 90vh;
  right: 1.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  min-width: 30rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 32px;

}

.allComment {
  padding-bottom: 0.5rem;

  .comment_box{
    border-radius: 5px;
    padding: 2px 5px;
    background-color: #1E1E1E;
  }

  .comment_box:hover{
    background-color: rgba(255, 0, 0, 0.178);

    .EditIcon, .DeleteIcon{
        display: block;
    }
  }

  .comment_box.active{
    background-color: rgba(255, 0, 0, 0.178);
  }

  .comment {
    flex-direction: row;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    cursor: pointer;
    transition: all 1s ease-in-out;
    text-transform: capitalize;

    .EditIcon, .DeleteIcon{
        display: none;
    }

    span {
      line-height: 1rem;
    }

    span:last-of-type {
      overflow-wrap: break-word;
      word-break: break-word;
      overflow: hidden;
    }
  }
}

.loader_box{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.219);
  top: 0;
  left: 0;
}

.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
}