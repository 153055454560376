.downloadTableBox {
  .MuiTableHead-root {
    th {
      font-weight: bold;
    }
  }

  tr.MuiTableRow-root {
    &.Mui-selected {
      background-color: rgba(0, 0, 0, 0);
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  .MuiTableCell-root {
    border-bottom-color: black;
    text-transform: capitalize;

    // &.MuiTableCell-body:nth-child(3)::after {
    //   content: "MB";
    // }

    &.MuiTableCell-body:nth-child(6)::after {
      // content: " Mins";
    }

    &.MuiTableCell-body:nth-child(7) {
      // color: rgb(158, 158, 158);
      // color: var(--text-disabled);
    }

    &.MuiTableCell-body:nth-child(7).approved {
      color: green !important;
    }

    &.MuiTableCell-body:nth-child(7).rejected {
      color: red !important;
    }
  }
}
