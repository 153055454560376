.download-conformbox {
  .MuiPaper-root {
    border-radius: 10px;
    padding: 20px 0;
  }

  .MuiDialogActions-root {
    .MuiButtonBase-root {
      border-radius: 20px;
      padding: 5px 30px;
      width: max-content;
      margin-top: 20px;
    }
  }
}
