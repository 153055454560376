.download-root {
  padding: 0.8rem;

  .download_head {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    // box-shadow: 4px 1px 40px 2px rgba(232, 232, 232, 1);
    padding: 0.8rem;
    margin-bottom: 1rem;
  }
}
