.allComment {
  padding-bottom: 0.5rem;

  .comment {
    padding: 0.5rem 1.5rem;
    cursor: pointer;

    span {
      line-height: 1rem;
    }

    span:last-of-type {
      overflow-wrap: break-word;
      word-break: break-word;
      overflow: hidden;
    }
  }
}

.active {
  background-color: rgb(32, 32, 32);
  border-radius: 3px;
  color: white;
}


.commentBody {
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  max-width: 30rem;
}
