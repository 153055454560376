@import "../../assets/styles/variables.scss";

.freelanceInfoHeader {
  padding: 0.7rem;
}
.badges {
  padding: 0.15rem 0.5rem;
  color: white;
  border-radius: 0.3rem;

  &.active {
    background: $success-green;
  }

  &.inactive {
    background: $error-red;
  }
}

.header {
  width: 100%;
  justify-content: space-between;
}

.userDetails,
.filtersection,
.userInfo {
  //   border-bottom: 1px solid $primary-lt;
}

.borderRight {
  border-right: 2px solid black;
}

.avatar {
  min-width: 6rem;
  max-width: 6rem;
  max-height: 7rem;
  border-radius: 0.3rem;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.videoInfoCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 13rem;
  box-shadow: -10px -10px 50px 0px #fff inset,
    10px 10px 50px 0px rgba(195, 195, 195, 0.25) inset,
    -18px -18px 50px 0px #fff, 18px 18px 50px 0px #dfdfdf;
  border-radius: 1rem;
}

.videoIcon {
  max-height: 4rem;
}

.timeline {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  box-shadow: -10px -10px 50px 0px #fff inset,
    10px 10px 50px 0px rgba(195, 195, 195, 0.25) inset,
    -18px -18px 50px 0px #fff, 18px 18px 50px 0px #dfdfdf;

  border-radius: 1rem;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.videoCard {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;

  .videoPreview {
    width: 12rem;
    height: 9rem;
    position: relative;

    .videoPreviewOverlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("../../assets/images/thumbnail-play.svg") center 2rem
        no-repeat;
      background-size: 7rem;
      top: 0;
    }

    .preview {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .play {
      width: 1.2rem;
    }
  }

  .videoDate {
    // border-bottom: 1px solid $primary-lt;
  }
}

.statusIndicator {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: black;
}

.timelineWrap {
  width: 15rem;
}

.infoWrap {
  width: calc(100% - 15rem);
}

.info {
  min-width: 4rem;
  max-width: 8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.addImg {
  max-width: 13.5rem;
  width: 100%;
  height: 13rem;
  // border: 1px solid $primary-clr;
  border-radius: 0.5rem;
  text-align: center;

  .upload {
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 50%;
    margin-top: 20px;
    object-fit: cover;
  }

  .uploadTitle {
    border-top: 1px solid $primary-clr;
  }
}

.uploadImg {
  position: absolute !important;
  opacity: 0;
}

.videoPreviewer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  z-index: 10;
}

.videoWrapper {
  width: 30rem;
  height: 20rem;
  position: relative;

  .title {
    position: absolute;
    top: 0;
    color: #c9c1c1;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .close {
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    z-index: 10;
    top: -0.6rem;
    right: -0.6rem;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.check {
  padding: 0 .3rem;
  flex-grow: 1;
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.noVideo {
  width: 14rem;
}

.videoCardBoxWidth {
  width: 12rem;
}

.btnImg {
  width: 1.5rem;
}

.syncIcon {
  height: 2.5rem;
}

.videosCount {
  position: absolute;
  top: 0;
  right: 15px;
}

.status {
  border-bottom: 1px solid gainsboro;
}


.typography_container {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20rem;
  cursor: default;
}