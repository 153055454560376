.vjs-big-play-button {
  background: url("../../assets/images/thumbnail-play.svg") no-repeat center
    center !important;
  background-size: 75px !important;
  border: none !important;
}

.vjs-big-play-button span {
  content: "" !important;
  display: none !important;
}
