@import "../../assets/styles/variables.scss";

.freelanceHeader {
  padding: 0.7rem;
}
.freelancerCardWrapper {
  .freeLancerCard {
    max-width: 20rem;
    width: 100%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
    border-radius: 1rem;
    .avatar {
      width: 6.5rem;
      height: 6.5rem;
      border-radius: 1rem;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.addImg {
  max-width: 13.5rem;
  width: 100%;
  height: 13rem;
  //   border: 1px solid $primary-clr;
  border-radius: 0.5rem;
  text-align: center;
  .upload {
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 50%;
    margin-top: 20px;
    object-fit: cover;
  }
  .uploadTitle {
    border-top: 1px solid $primary-clr;
  }
}
.passwordsec {
  border-top: 1px solid $primary-lt;
}
.uploadImg {
  position: absolute !important;
  opacity: 0;
}
