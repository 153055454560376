@import "../../assets/styles/variables.scss";

.loginWrapper {
  min-height: 100vh;
  background: url("../../assets/images/GFCBg.png") center no-repeat;
  background-size: cover;
  color: $font-clr-1;

  &.helpBG {
  background: url("../../assets/images/BG.png") center no-repeat;
  }
  .contentWrap {
    max-width: 30rem;
    width: 100%;
  }
}
