.RSPagination-root {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  .MuiPagination-ul {
    flex-wrap: nowrap;
  }

  .MuiPaginationItem-root,
  .MuiButton-root {
    background-color: transparent;
    border-color: var(--text-disabled);
    color: var(--text-primary);
    text-transform: capitalize;
    &:hover {
      background-color: inherit;
      border-color: var(--text-primary);
    }

    &.Mui-selected,
    &.Mui-selected:hover {
      background-color: #e80303;
      color: white;

      border: none;
    }
    &.Mui-disabled {
      color: var(--text-disabled);

      svg {
        fill: var(--text-disabled);
      }
    }
  }

  .MuiButton-icon {
    margin: 0;
  }
}

.pagination-img {
  filter: invert(0.7);
}
