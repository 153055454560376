#admin-sort-popover {
    .MuiPaper-root {
      background-color: rgb(244, 244, 244);
      box-shadow: 0 0 5px rgb(214, 214, 214);
    }
  
    .MuiOutlinedInput-root {
      border-radius: 0;
      background-color: white;
    }
  
    .MuiOutlinedInput-input {
      padding: 0.6rem;
    }
  
    .MuiList-root {
      padding: 0;
  
      li {
        span {
          font-size: 0.9rem;
        }
        background-color: rgb(238, 238, 238);
        padding: 0;
        border-bottom: 1px solid rgb(209, 209, 209);
      }
    }
  }
  