$primary-clr: #000000;
$primary-lt: #bfe6f7;
$primary-lt-2: #3cb3e3;
$primary-lt-3: #f9fdff;

$font-clr-1: #606e87;
$font-clr-2: #afafaf;
$font-clr-3: #646464;

$white: #fff;
$success-green: #49a800;
$error-red: #ff0000;
